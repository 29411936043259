<template>
  <div id="deviceRegisterTab">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="deviceCode" label="设备编号" align="center">
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" align="center">
        </el-table-column>
        <el-table-column prop="parkingLotName" label="停车场" align="center">
        </el-table-column>
        <el-table-column prop="channelName" label="出入口" align="center">
        </el-table-column>
        <el-table-column prop="channelNum" label="通道数量" align="center">
        </el-table-column>
        <el-table-column prop="ip" label="注册IP(端口)" align="center">
        </el-table-column>
        <el-table-column prop="deviceType" label="设备类型" align="center">
        </el-table-column>
        <el-table-column prop="soundVolume" label="音量" align="center">
        </el-table-column>
        <el-table-column prop="screenType" label="显示屏类型" align="center">
        </el-table-column>
        <el-table-column prop="user" label="用户名 - 密码" align="center">
        </el-table-column>
        <el-table-column prop="createAt" label="创建时间" align="center">
        </el-table-column
        >3
        <el-table-column prop="loggedIn" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.loggedIn === '是'" class="loggedIn"
              >在线</span
            >
            <span v-else class="offline">离线</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="checkMonitor(scope.row)"
              v-if="
                buttonList.includes(
                  'passagewayList-detail-deviceRegisterTab-checkMonitor'
                )
              "
              >查看监控</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <video-dialog ref="videoDialog" />
  </div>
</template>
<script>
export default {
  components: {
    videoDialog: () => import("@/components/videoDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    checkMonitor(row) {
      this.$refs.videoDialog.deviceRegisterId = row.deviceRegisterId;
      this.$refs.videoDialog.title = row.channelName ?? row.deviceCode;
      this.$refs.videoDialog.show = true;
    },
    reset() {
      this.filtrate = {
        keyword: "",
      };
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/device/register/list", {
          channelId: this.$route.params.id,
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.createAt = this.$moment(item.createAt).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.ip = `${item.deviceIp}:${item.devicePort}`;
            item.user = `${item.username} - ${item.password}`;
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#deviceRegisterTab {
  .loggedIn,
  .offline {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1da05e;
    &::before {
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #1da05e;

      margin-right: 5px;
    }
  }
  .offline {
    color: #8d8d8d;
    &::before {
      background-color: #8d8d8d;
    }
  }
}
</style>
